import {useContext, useEffect} from "react";

//Styles
import 'bootstrap/dist/css/bootstrap.css';
import './styles/index.css';


import {Context} from ".";
import {observer} from  "mobx-react-lite"

import {BrowserRouter as Router} from 'react-router-dom'
import {useRoutes} from './routes'

import Navbar from './components/Navbar'
import NavbarAuth from './components/NavbarAuth'
import ButtonUp from './components/ButtonUp'


function App() {

  const {store} = useContext(Context)
  const isAuthenticated = store.isAuth
  const isActivated = store.user.isActivated
  const isLoading = store.isLoading

  //console.log(isAuthenticated);
  const routes = useRoutes({isAuthenticated, isActivated, isLoading})

  useEffect( ()=>{
    if(localStorage.getItem('token')){
      store.checkAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  if (isLoading){
    return (
      <div style={{
        position: 'absolute',
        margin: 'auto',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100px',
        height: '100px',
      }}> 
        <div className="columns">
          <div className="column">
            <span className="loader"></span>
          </div>
          <div className="column">
            <span>KApp</span>
          <br/>Загрузка...
          </div>
        </div>
      </div>
    )
  }

  return (
    <Router >
      <div>
      <ButtonUp />
      {isAuthenticated ? <Navbar /> : <NavbarAuth />}
        <div className='container'>
          <div className='row'>
            {routes}
          </div>
        </div>
      </div>
    </Router>
  )

}

export default observer(App);
