import React, {} from 'react';

export const Activated = () => {

    return (
        <div className='col-md-5 mx-auto' style={{marginTop: 100}}>
            <div className="box">
            
            <h4>Профиль не активирован, обратитесь к менеджеру</h4>
            
            </div>
        </div>
    )
}