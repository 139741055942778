import $api from "../http";

export default class AuthService {

    static async login(email, password){
        return $api.post('/auth/login', {email, password})
    }

    static async registration(email, password, code, inn, fio){
        return $api.post('/auth/registration', {email, password, code, inn, fio})
    }

    static async logout(){
        return $api.post('/auth/logout')
    }

    static async activated(activatedCode,Name2,Name1){
        return $api.post('/auth/activated', {activatedCode,Name2,Name1})
    }
}

