import React, {useState, useContext, useEffect} from 'react';
import {Context} from "../index";
import {observer} from "mobx-react-lite";


const Navbar = () => {
    const {store} = useContext(Context);
    console.log(store.user);
    return (
        <>
        <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">                
        <div className="collapse navbar-collapse" id="navbarNavDropdown" style={{marginLeft: 20}}>
            <ul className="navbar-nav">
                
                <li className="nav-item"> 
                    <a className="nav-link"
                        style={{marginRight: 10}}
                        href="/home"
                        >
                            Главная
                    </a>
                </li>
                <li className="nav-item"> 
                    <a className="nav-link"
                        style={{marginRight: 10}}
                        href="/history"
                        >
                            История
                    </a>
                </li>


            </ul>
        </div>
        <form className="form-inline" style={{marginRight: 20}}>
            <button className="btn btn-secondary" disabled={true} >{store.user.email}</button>
            <button
                style={{marginLeft: 10}}
                className="btn btn-danger"
                onClick={()=>store.logout()}>
                Выход
            </button>
        </form>
        </nav>
        </div>
    </>
    )

}

export default observer(Navbar);