import axios from 'axios'

export const API_URL = `https://api-bv-opt.foden.cc/api`

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})

$api.interceptors.request.use(config =>{
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
})

$api.interceptors.response.use((config)=>{
    return config
},async (error) => {
    const originalRequest = error.config
    if(error.response.status === 401 && error.config && !error.config._isReady){
        originalRequest._isReady = true
        try{
            console.log('ОБНОВИЛ ТОКЕН')
            const response = await axios.get(`${API_URL}/auth/refresh`, {withCredentials: true})
            console.log(response.data.userData.accessToken)
            localStorage.setItem('token', response.data.userData.accessToken)
            return $api.request(originalRequest)
        } catch(e){
            console.log('НЕ АВТОРИЗОВАН')
        }
        
    }
    throw error
})


export default $api