import React, {useState, useEffect} from 'react';
import ProductService from "../../service/ProductService";

export const Home = () => {
    const [reply, setReply] = useState('')
    const [products, setProducts] = useState('')
    const [mapAccordion, setMapAccordion] = useState(new Map())
    const [mapModify, setMapModify] = useState(new Map())

    useEffect( ()=>{
        GetProduct()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function GetProduct(){
        const response = await ProductService.getProducts() // Получить товары
        console.log(response.data)
        setProducts(response.data)

        for (let i = 0; i < products.length; i++) {
            setMapAccordion(map => new Map(map.set(products[i].id, false)))
        }

        for (let i = 0; i < response.data.length; i++) {
            for (let o = 0; o < response.data[i].modify.length; o++) {
              setMapModify(map => new Map(map.set(response.data[i].modify[o].sku, 0))) // Кол-во в корзине до сервера
            }
        }
        setReply('')
    }

    async function CartProduct(){

        // Создаем новый Map с положительными значениями
        const positiveValuesMap = new Map();

        // Итерируем по оригинальному Map и добавляем только положительные значения в новый Map
        for (const [key, value] of mapModify) {
            if (value > 0) {
                positiveValuesMap.set(key, value);
            }
        }
        console.log(positiveValuesMap);

        // Преобразуем Map в массив объектов
        const resultArray = Array.from(positiveValuesMap, ([sku, qt]) => ({ sku, qt: Number(qt) }));
        console.log(resultArray);
        if (resultArray.length !== 0) {
            setReply()
            let products = resultArray
            try {
                const response = await ProductService.setCart(products)
                setReply(response.data)
                console.log(response.data);
                GetProduct()
            } catch (e) {
                console.log(e);
            }
        }
    }
  

    return (
        <div className="card g-4">
            <div className="row g-4" style={{marginTop: 5}}>
                <div className="col-5">
                    <>{products &&<div>
                        {products.map(products => 
                            <div className="accordion mb-3" id="accordionPanelsStayOpenExample" key={products.id}>
                                <div className="accordion-item"> 
                                    <h2 className="accordion-header ">
                                        <button className="accordion-button " type="button" onClick={() => setMapAccordion(map => new Map(map.set(products.id, !mapAccordion.get(products.id))))}>
                                            {products.name}
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                        {mapAccordion.get(products.id) &&
                                            <div className="accordion-body">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: "15%"}}>Название</th>
                                                            <th style={{width: "5%"}}>Цена</th>
                                                            <th style={{width: "5%"}}>Кол-во</th>          
                                                            <th style={{width: "5%"}}>Наличие</th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {products.modify.map(modify =>
                                                            <tr key={modify.id}>
                                                                <td>{modify.name}</td>
                                                                <td>{modify.price}</td>
                                                                <td><input type="number" 
                                                                    min="0" step="1" 
                                                                    value={mapModify.get(modify.sku)}
                                                                    className={`form-control`}
                                                                    onChange={e => 
                                                                         setMapModify(map => new Map(map.set(modify.sku, e.target.value)))
                                                                    }
                                                                /></td>
                                                                <td>{modify.qt}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>}</>
                </div>
                <div className="col-1">
                </div>
                <div className="col-6">
                <>{products &&<div>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th style={{width: "15%"}}>Название</th>
                                <th style={{width: "5%"}}>Цена</th>
                                <th style={{width: "5%"}}>Кол-во</th>
                                <th style={{width: "5%"}}>Сумма</th>         
                            </tr>
                        </thead>
                        {products.map(products =>                
                            <tbody>
                                {products.modify.map(modify =>
                                    (mapModify.get(modify.sku) < 1) ? null : (
                                        <tr key={modify.id}>
                                            <td>{modify.name}</td>
                                            <td>{modify.price}</td>
                                            <td>{mapModify.get(modify.sku)}</td>
                                            <td>{
                                                (mapModify.get(modify.sku) * Number(parseFloat(modify.price.replace(',', '.')))).toFixed(2)
                                            }</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        )}</table>

                        <table className="table table-sm">
                        <thead>
                            <tr>
                                <th style={{width: "15%"}}></th>
                                <th style={{width: "5%"}}></th>
                                <th style={{width: "5%"}}>
                                <button 
                                        className="btn btn-danger" 
                                        onClick={GetProduct}
                                        >Очистить заказ
                                </button>
                                </th>
                                <th style={{width: "5%"}}><button 
                                        className="btn btn-success" 
                                        onClick={CartProduct}
                                        >Сохранить заказ
                                </button></th>         
                            </tr>
                        </thead>
                        </table>

                    </div>}</>
                    {reply}
                </div>
            </div>
        </div>
    )
}