import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";


const ButtonUp = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Обработчик прокрутки страницы
    const handleScroll = () => {
      const scrollTop = window.scrollY;
  
      // Определение, должна ли кнопка быть видимой
      if (scrollTop > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    // Добавление слушателя событий при монтировании компонента
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      // Убираем слушателя событий при размонтировании компонента
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    // Обработчик нажатия на кнопку
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  
    return (
      <div>
        {isVisible && (
          <button
            onClick={scrollToTop}
            style={{
              position: 'fixed',
              bottom: '20px',
              left: '20px',
              zIndex: '999',
              background: 'black',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>&#9650;</span>
          </button>
        )}
      </div>
    );
  };

export default observer(ButtonUp);