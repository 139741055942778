import React, {useState, useEffect} from 'react';
import ProductService from "../../service/ProductService";


export const History = () => {
    const [reply, setReply] = useState('')
    const [history, setHistory] = useState('')

    useEffect( ()=>{
        GetHistoryOut()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    
    async function GetHistoryOut(){
        const response = await ProductService.getHistoryOut() // Получить историю
        setHistory(response.data)
        console.log(response);
    }


  
    return (
        <div className="card g-4">
            <h1>Ваши продажи</h1>
                <>{history &&<div>
                    
                    

                        <div>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th style={{width: "30%"}}>Артикул</th>
                                        <th style={{width: "20%"}}>Кол-во</th>
                                        <th style={{width: "20%"}}>Цена</th>
                                        <th style={{width: "20%"}}>Сумма</th> 
                                    </tr>
                                </thead>
                            </table>
                        </div>

                    {history.map(history =>
                    
                        <div>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <td style={{width: "30%"}}>№{history.id}</td>
                                        <td style={{width: "20%"}}>{history.date}</td>
                                        <td style={{width: "20%"}}></td>
                                        <td style={{width: "20%"}}></td> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {history.info.map(info =>
                                        <tr key={info.id_sql}>
                                            <td>{info.sku}</td>
                                            <td>{info.qt}</td>
                                            <td>€{info.price}</td>
                                            <td>€{info.qt*info.price}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    
                    )}
                    
                </div>}</>
        </div>
    )
}