import React, {useState} from 'react';
import {observer} from "mobx-react-lite";



const NavbarAuth = () => {
    
    return (
    <>
        {/* <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className='container'>
                <div className='col'> </div>
                    <h1 className="navbar-brand">Пройдете авторизацию</h1>
                <div className='col'> </div>
            </div>
        </nav> */}
    </>
    )
}

export default observer(NavbarAuth);