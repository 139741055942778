// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma-rtl.min.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-filter {
    position: sticky;
    top: 20px;
    z-index: 10;
}

`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAIA;IACI,gBAAgB;IAChB,SAAS;IACT,WAAW;AACf","sourcesContent":["@import \"https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma-rtl.min.css\";\r\n\r\n\r\n\r\n.fixed-filter {\r\n    position: sticky;\r\n    top: 20px;\r\n    z-index: 10;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
