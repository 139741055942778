import $api from "../http";

export default class ProductService {

    static async getProducts(){
        return $api.post('/product/getproduct')
    }
    static async setCart(products){
        return $api.post('/product/setcart', products)
    }



    static async getHistoryOut(){
        return $api.post('/product/gethistoryout')
    }



}

